<!-- 志愿者详情弹窗页面 -->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
      >
        <a-descriptions-item label="姓名">
          {{ personerIfo.residentName }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ personerIfo.gender }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ personerIfo.identityCard }}
        </a-descriptions-item>
        <a-descriptions-item label="年龄">
          {{
            personerIfo.identityCard
              ? new Date().getFullYear() -
                parseInt(personerIfo.identityCard.slice(6, 10)) -
                (new Date().getMonth() + 1 <
                  parseInt(personerIfo.identityCard.slice(10, 12)))
              : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="出生日期">
          {{ personerIfo.birthDate }}
        </a-descriptions-item>
        <a-descriptions-item label="联系电话">
          {{ volunteerInfo.contactWay }}
        </a-descriptions-item>
        <a-descriptions-item label="所属网格">
          {{ personerIfo.gridName }}
        </a-descriptions-item>
        <a-descriptions-item label="详细地址" :span="2">
          {{ volunteerInfo.address }}
        </a-descriptions-item>
      </a-descriptions>
      <!-- 团队成员 -->
      <div
        style="
          border-left: 4px solid #2a5cff;
          font-size: 0.08rem;
          font-weight: bold;
          color: black;
          line-height: 100%;
          margin-bottom: 15px;
        "
      >
        &nbsp;团队信息
      </div>
      <ax-table
        ref="tableBox"
        :columns="columns"
        :show-search="false"
        :showToolBar="false"
        :dataSourceApi="dataSourceApis"
        :dataSourceParams="dataSourceParams"
      >
      </ax-table>
    </el-dialog>
  </div>
</template>
  <script>
import api from "./api";
export default {
  components: {},
  data() {
    return {
      title: "志愿者详情",
      visible: false,
      id: "",
      businessId: "",
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "团队名称",
            dataIndex: "groupName",
            ellipsis: true,
            width: 50,
          },
          {
            title: "入团时间",
            dataIndex: "createTime",
            ellipsis: true,
            width: 50,
          },
        ],
        true //去掉序号
      ),
      dataSourceApis: api.volunteerGroupDetail,
      dataSourceParams: {
        // volunteerId: "",
      },
      personTableData: [],
      personerIfo: {},
      volunteerInfo: {},
      showTable: false,
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
      this.$nextTick(() => {
        this.$refs.tableBox.getDataSource();
      });
    },
  },
  computed: {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      if (record.id) {
        this.showTable = true;
        this.id = record.id;
        this.businessId = record.id;
        this.dataSourceParams.volunteerId = record.id;
        this.getPersonInfo(record.residentId);
        this.getVolunteerInfo(record.id);
      }
    },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.visible = false;
      this.showTable = false;
      this.$emit("refsh");
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.personerIfo = res.data;
      console.log(this.personerIfo, "居民详情");
    },
    // 根据id获取志愿者详情信息（联系电话 详细地址 所属团队）
    async getVolunteerInfo(id) {
      const res = await api.getVolunteerInfoById(id);
      this.volunteerInfo = res.data;
      console.log(this.volunteerInfo, "志愿者详情");
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #556bff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
</style>